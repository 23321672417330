html {
  font-size: 16px;
  background-color: #282c34;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="password"] {
  font-size: 1rem;
}
